import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import Loader from "./components/loader/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


window.apiUrl = 'https://cashbackoffers.win/spa_api/api';
window.baseUrl = "http://127.0.0.1:8080";

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Suspense fallback={<Loader />}>
        <App />
        <ToastContainer />
      </Suspense>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

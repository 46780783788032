import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import HttpApi from "../api/http";
import { ToastError } from "../components/Toast";
import { getUserData } from "../helpers/UserHelper";

const Sidebar = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [activePaymentGateways, setActivePaymentGateways] = useState([]);

  let navigate = useNavigate();
  let userData = getUserData();

  const createInvoice = (e) => {
    e.preventDefault();
    let formData = {};
    const form = new FormData(e.currentTarget);
    for (let [key, value] of form.entries()) {
      formData[key] = value;
    }

    if (formData.amount < 10) {
      ToastError("Minimum deposit is $10");
      return;
    }

    if (!("paymentMethod" in formData)) {
      ToastError("Please select Payment Method !");
      return;
    }

    HttpApi.createInvoice(formData)
      .then((response) => {
        navigate("/invoices/" + response.data?.invoice_no);
        setmodal_center(!modal_center);
      })
      .catch((e) => {
        ToastError(e.response.data.message);
      });
  };

  const addFund = () => {
    if (userData.plan_id == null) {
      navigate("/plans");
    } else {
      HttpApi.addFund()
        .then((response) => {
          setActivePaymentGateways(response.data);
          setmodal_center(!modal_center);
        })
        .catch((e) => {
          ToastError(e.response.data.message);
        });
    }
  };

  // Sidebar
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  const location = useLocation();
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      let matchingMenuItem = null;
      const div = document.getElementById("sidebar");
      const items = div.getElementsByClassName("menu-item");
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove("active");
      }
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].childNodes[0].pathname) {
          matchingMenuItem = items[i];
          items[i].classList.add("active");
          break;
        }
      }
      if (matchingMenuItem) {
        //activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar" className="app-sidebar">
          <div
            className="app-sidebar-content"
            data-scrollbar="true"
            data-height="100%"
          >
            <div className="menu">
              <div className="d-flex align-items-center mb-3">
                <div className="flex-fill px-3">
                  <div className="fw-bold text-truncate w-100px username">
                    {userData?.name}
                  </div>
                  <div className="fs-12px text-white text-opacity-50">
                    Balance : ${userData?.balance}
                  </div>
                </div>
                <button
                  onClick={addFund}
                  className="btn btn-sm btn-outline-theme fs-11px"
                >
                  Add Funds
                </button>
              </div>
              <div className="menu-header">Navigation</div>
              <div className="menu-item">
                <Link to="/home" className="menu-link">
                  <span className="menu-icon">
                    <i className="bi bi-cpu"></i>
                  </span>
                  <span className="menu-text">Home</span>
                </Link>
              </div>
              <div className="menu-item">
                <Link to="/shop/email-pass" className="menu-link">
                  <span className="menu-icon">
                    <i className="bi bi-bag-check"></i>
                    <span className="w-5px h-5px rounded-3 bg-theme position-absolute top-0 end-0 mt-3px me-3px"></span>
                  </span>
                  <span className="menu-text">Shop Email Pass</span>
                </Link>
              </div>
              <div className="menu-header">User Area</div>
              <div className="menu-item">
                <Link to="/orders" className="menu-link">
                  <span className="menu-icon">
                    <i className="fa fa-database"></i>
                  </span>
                  <span className="menu-text">My Orders</span>
                </Link>
              </div>
              <div className="menu-item">
                <Link to="/invoices" className="menu-link">
                  <span className="menu-icon">
                    <i className="bi bi-collection"></i>
                  </span>
                  <span className="menu-text">Invoices</span>
                </Link>
              </div>
              <div className="menu-header">Manage Account</div>
              <div className="menu-item">
                <Link to="/account/profile" className="menu-link">
                  <span className="menu-icon">
                    <i className="bi bi-people"></i>
                  </span>
                  <span className="menu-text">Manage Profile</span>
                </Link>
              </div>
              <div className="menu-item">
                <Link to="/account/change-password" className="menu-link">
                  <span className="menu-icon">
                    <i className="bi bi-gear"></i>
                  </span>
                  <span className="menu-text">Change Password</span>
                </Link>
              </div>
              <div className="menu-header">Others</div>
              <div className="menu-item">
                <Link to="/support" className="menu-link">
                  <span className="menu-icon">
                    <i className="fas fa-comments"></i>
                  </span>
                  <span className="menu-text">Support</span>
                </Link>
              </div>
              <div className="menu-item">
                <Link to="/logout" className="menu-link">
                  <span className="menu-icon">
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                  <span className="menu-text">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          setmodal_center(!modal_center);
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Fund</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={createInvoice}>
            <Row className="mb-3">
              <label className="col-md-4 col-form-label">Amount</label>
              <div className="col-md-8">
                <input
                  className="form-control"
                  type="number"
                  name="amount"
                  defaultValue={10}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <label className="col-md-4 col-form-label">Payment Method</label>
              <div className="col-md-8">
                {activePaymentGateways.map((p) => (
                  <div className="form-check form-check-inline font-size-16">
                    <input
                      id={p.name}
                      name="paymentMethod"
                      type="radio"
                      className="form-check-input form-check-input"
                      value={p.name}
                    />
                    <label
                      htmlFor={p.name}
                      className="form-check-label font-size-13 form-label"
                    >
                      {p.name == "btc" ? (
                        <i className="fab fa-bitcoin me-1 font-size-20 align-top"></i>
                      ) : null}
                      {p.name == "perfect_money" ? (
                        <i className="fab fa-product-hunt me-1 font-size-20 align-top"></i>
                      ) : null}
                      {p.title}
                    </label>
                  </div>
                ))}
              </div>
            </Row>
            <button className="btn btn-sm btn-outline-theme" type="submit">
              Add Fund
            </button>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Sidebar;

import axios from "axios";

let HEADERS = {
    "Content-type": "application/json",
    "Accept": "application/json",
}

//if(localStorage.getItem("token")) HEADERS['Authorization'] =  `Bearer ${localStorage.getItem("token")}`;

const http = axios.create({
  baseURL: "http://panel.cashbackoffers.win/api/shop_2",
  /*baseURL: "http://127.0.0.1/mirc/ep_master_panel_lara/panel/api/shop_2",*/
  timeout: 10000,
  headers: HEADERS,
});

http.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  if(token)
      config.headers["Authorization"] = "Bearer " + token;
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(error.response.status == 401 && error.response.data.message == "Unauthenticated."){
      localStorage.removeItem('token');
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

const login = (params) => {
  return http.post("/login", params);
};

const register = (params) => {
  return http.post("/register", params);
};

const getNews = (params) => {
  return http.get("/news", { params });
};

const getFaq = (params) => {
  return http.get("/faq", { params });
};

const getSupportContact = (params) => {
  return http.get("/support-contact", { params });
};

const getShopEmailPass = (params) => {
  return http.get("/shop/email-pass", { params });
};

const cart = (params) => {
  return http.post("/cart", params);
};

const getProfile = (params) => {
  return http.get("/profile", params);
};

const updateProfile = (params) => {
  return http.post("/profile/update-profile", params);
};

const ChangePassword = (params) => {
  return http.post("/profile/change-password", params);
};

const getOrders = (params) => {
  return http.get("/orders", { params });
};
const getOrder = (order_no) => {
  return http.get("/orders/"+order_no, null);
};

const getInvoices = (params) => {
  return http.get("/invoices", { params });
};

const getInvoice = (invoice_no) => {
  return http.get("/invoices/" + invoice_no);
};

const createInvoice = (params) => {
  return http.post("/invoices/create", params);
};

const cancellInvoice = (params) => {
  return http.post("/invoices/cancell", params);
};

const checkBtcPayment = (params) => {
  return http.post("/invoices/checkBtcPayment", params);
};

const addFund = (params) => {
  return http.get("/add-fund", params);
};

const createOrder = (params) => {
  return http.post("/orders/create", params);
};

const getPlans = (params) => {
  return http.get("/plans", params);
};

const createPlanInvoice = (params) => {
  return http.post("/invoices/createPlanInvoice", params);
};

const HttpApi = {
  login,
  register,
  getNews,
  getFaq,
  getSupportContact,
  getShopEmailPass,
  cart,
  getProfile,
  updateProfile,
  ChangePassword,
  getOrders,
  createOrder,
  getOrder,
  getInvoices,
  getInvoice,
  createInvoice,
  cancellInvoice,
  checkBtcPayment,
  addFund,
  getPlans,
  createPlanInvoice,
}

export default HttpApi;
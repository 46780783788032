import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const AuthCheck = () => {
    const { auth } = useAuth();
    const location = useLocation();
    return (
        auth
            ? <Navigate to="/home" state={{ from: location }} replace />
            : <Outlet/>
            
    );
}

export default AuthCheck;
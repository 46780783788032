import React from "react";
import { Link } from "react-router-dom";
import { getCartCount } from "../helpers/EmailPassExtra";

const Header = () => {
  return (
    <div id="header" className="app-header">

      <div className="brand">
        <a href="#" className="brand-logo">
          <span className="brand-img">
            <span className="brand-img-text text-theme">M</span>
          </span>
          <span className="brand-text">MIRCBOOT SHOP</span>
        </a>
      </div>

      <div className="menu">
        <div className="menu-item dropdown">
          <Link
            to="/cart"
            className="menu-link"
          >
            <div className="menu-icon">
              <i className="fas fa-shopping-basket nav-icon"></i>
              <span className="badge rounded-pill bg-danger" id="cartItemsCount">{getCartCount()}</span>
            </div>
          </Link>
        </div>
        
        
        <div className="menu-item dropdown dropdown-mobile-full" style={{marginRight: "2em"}}>
          <a
            href="#"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-img online">
              <img src="/assets/img/user/profile.jpg" height="60px" />
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
            <Link className="dropdown-item d-flex align-items-center" to="/account/profile">
              PROFILE
              <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <Link className="dropdown-item d-flex align-items-center" to="/orders">
              MY ORDERS
              <i className="fa fa-database ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <div className="dropdown-divider"></div>
            <Link className="dropdown-item d-flex align-items-center" to="/logout">
              LOGOUT
              <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i>
            </Link>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Header;

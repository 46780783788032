import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";

const UserLayout = () => {
  // Scroll Top to 0
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <button className="app-sidebar-mobile-backdrop"></button>
      <div id="content" className="app-content">
        <Outlet />
      </div>
    </React.Fragment>
  );
};
export default UserLayout;

export const getUserData = () => {
    try{
        var userData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : null;
    }catch(e){
        var userData = null;
    }
    
    return userData;
}

export default getUserData;
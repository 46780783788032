import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const defaultDelay = 5000;

export const ToastSuccess = (msg, delay) => {
    toast.success(msg, {autoClose: delay ?? defaultDelay });
}

export const ToastError = (msg, delay) => {
    toast.error(msg, {autoClose: delay ?? defaultDelay});
}

export default ToastSuccess;
const Loader = () => (
  <div id="status">
    <div className="spinner-chase">
      <div className="chase-dot"></div>
      <div className="chase-dot"></div>
      <div className="chase-dot"></div>
      <div className="chase-dot"></div>
      <div className="chase-dot"></div>
      <div className="chase-dot"></div>
    </div>
  </div>
);
export default Loader;
